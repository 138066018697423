import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import * as moment from 'moment';

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { DayCalendarSkeleton } from '@mui/x-date-pickers/DayCalendarSkeleton';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { fiFI, svSE } from '@mui/x-date-pickers/locales';
import InputLabel from '@mui/material/InputLabel';
import { withStyles } from '@mui/styles';

const styles = () => ({
  root: {},
  calendar: {
    marginBottom: '25px',
    marginTop: '5px',
  },
});

const ServerDay = props => {
  const { availability = [], day, outsideCurrentMonth, classes, ...other } = props;

  const hasAvailability =
    availability.length > 0 && !outsideCurrentMonth && availability.includes(day.format('YYYY-MM-DD'));

  return (
    <PickersDay
      {...other}
      outsideCurrentMonth={outsideCurrentMonth}
      day={day}
      style={{ backgroundColor: hasAvailability ? '#a9ddad' : '' }}
    />
  );
};

const Calendar = ({ classes, className, label, disabled, value, required, lang, availableTimes, ...otherProps }) => {
  const locale = lang ? lang : 'fi';

  moment.locale(locale);

  const today = moment();

  const availability =
    availableTimes && availableTimes.map(item => item.attributes.timeSlots.length > 0 && item.attributes.date);

  const calendarLocale = lang === 'fi' ? fiFI : lang === 'sv' ? svSE : '';

  return (
    <>
      <InputLabel shrink style={{ textAlign: 'left', fontSize: '20px', color: '#000' }}>
        {label} {required && '*'}
      </InputLabel>
      <LocalizationProvider
        dateAdapter={AdapterMoment}
        adapterLocale={locale}
        localeText={get(calendarLocale, ['components', 'MuiLocalizationProvider', 'defaultProps', 'localeText'])}>
        <DateCalendar
          {...otherProps}
          className={classes.calendar}
          disabled={disabled}
          disablePast
          displayWeekNumber
          maxDate={today.add(1, 'years')}
          renderLoading={() => <DayCalendarSkeleton />}
          required={required}
          slots={{
            day: ServerDay,
          }}
          slotProps={{
            day: {
              availability,
            },
          }}
          views={['day']}
        />
      </LocalizationProvider>
    </>
  );
};

Calendar.propTypes = {
  classes: PropTypes.object,
};

Calendar.defaultProps = {
  classes: {},
};

export default withStyles(styles)(Calendar);
