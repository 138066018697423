import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { Link as MuiLink } from '@mui/material';
import { withStyles } from '@mui/styles';

const styles = () => ({
  root: {},
});

function Link({ classes, className, children, to, ...otherProps }) {
  const internal = useMemo(() => /^\/(?!\/)/.test(to), [to]);

  if (internal) {
    return (
      <MuiLink {...otherProps} className={clsx(classes.root, className)} to={to}>
        {children}
      </MuiLink>
    );
  }

  return (
    <MuiLink {...otherProps} className={clsx(classes.root, className)} href={to}>
      {children}
    </MuiLink>
  );
}

Link.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.node,
  to: PropTypes.string,
};

Link.defaultProps = {
  classes: {},
  className: null,
  children: null,
  to: null,
};

export default withStyles(styles)(Link);
