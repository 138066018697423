


export function getPageTitle(pages) {
    const subPages = Array.isArray(pages) ? pages.join(' - ') : pages;
    return subPages + ' | ' + capitalizeFirstLetter(process.env.REACT_APP_BRAND);  
}

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}