import React from 'react';
import * as R from 'ramda';
import { Helmet } from 'react-helmet';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import Button from '@mui/material/Button';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import { withStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next';

import { clearData } from '../../store/actions/availabilityActions';
import { clearDataAppointment, getAppointmentLogin } from '../../store/actions/appointmentActions';
import { clearDataTracking } from '../../store/actions/trackingActions';

import { getPageTitle } from '../../utils/SEO';

import { validate, returnField } from '../../utils/FormHelpers';

const inlineStyles = theme => ({
  button: {
    width: '100%',
    fontSize: 14,
    height: 50,
    [theme.breakpoints.up('md')]: {
      height: 58,
    },
  },
  expansionPanel: {
    width: '100%',
  },
  formGrid: {
    paddingLeft: 31,
    paddingRight: 31,
    order: 2,
    [theme.breakpoints.up('md')]: {
      order: 2,
    },
  },
  paddingLeftRight: {
    paddingLeft: 31,
    paddingRight: 31,
    order: 1,
  },
  content: {
    marginTop: 20,
  },
  note: {
    color: 'rgb(86,99,110)',
    marginTop: 20,
  },
  h1: {},
  h3: {
    color: theme.palette.primary.main,
    textAlign: 'left',
  },
  selectedServiceBoxWrapper: {
    order: 2,
    [theme.breakpoints.up('md')]: {
      order: 3,
    },
  },
  statusMessage: {
    color: theme.palette.status.error,
    marginBottom: 20,
  },
});

const required = value => (value ? undefined : 'required');

/**
 * Appointment login / logout / successful cancellation view
 */

const AppointmentLogin = ({
  classes,
  handleSubmit,
  reservationDetails,
  getAppointmentLogin,
  submitting,
  cancelled,
  logout,
  t,
  clearData,
  clearDataAppointment,
  clearDataTracking,
  i18n,
}) => {
  if (logout) {
    clearData();
    clearDataAppointment();
    clearDataTracking();
  }

  const getLocale = () => i18n.language;

  let reservationStatus = '';

  if (R.path(['status'], reservationDetails) === 'Failure' || R.path(['status'], reservationDetails) === 'Exception') {
    reservationStatus = t('views.appointmentLogin.reservationNotFound');
  }

  return (
    <Grid container direction="row" spacing={0}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{getPageTitle(t('views.appointmentLogin.myReservation'))}</title>
      </Helmet>
      <Grid item xs={12} className={classes.paddingLeftRight}>
        <Typography variant="h1" className={classes.h1}>
          {t('views.appointmentLogin.ownReservation')}
        </Typography>
      </Grid>

      {/**
       * LOGOUT VIEW
       */}
      {!cancelled && logout && (
        <Grid item xs={12} className={classes.paddingLeftRight}>
          <Typography variant="body1" align="left">
            {t('views.appointmentLogin.loggedOut')}
          </Typography>

          <Grid container>
            <Grid item xs={12} align="left" style={{ marginTop: '20px' }}>
              <Button disableRipple type="submit" color="secondary" variant="contained">
                <strong>{t('views.backButton.backToFrontpage')}</strong>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}

      {/**
       * CANCELLED VIEW
       */}
      {cancelled && !logout && (
        <Grid item xs={12} className={classes.paddingLeftRight}>
          <Typography variant="body1" align="left">
            {t('views.appointmentLogin.thankYou')}
          </Typography>
          <div style={{ marginTop: '20px' }} align="left">
            <Button disableRipple color="secondary" variant="contained" href={`/${getLocale()}`}>
              {t('views.appointmentLogin.reserveNewTime')}
            </Button>
            <Button
              disableRipple
              color="secondary"
              variant="contained"
              href={`/${getLocale()}/appointment/my-appointments`}
              style={{ marginLeft: '20px' }}>
              {t('views.appointmentLogin.backToOwnAppointments')}
            </Button>
          </div>
        </Grid>
      )}

      {/**
       * LOGIN VIEW
       */}
      {!cancelled && !logout && (
        <Grid item xs={12} className={classes.formGrid}>
          <Typography variant="body1" align="left">
            {t('views.appointmentLogin.fillInInformation')}
          </Typography>
          <form className={classes.content} onSubmit={handleSubmit(getAppointmentLogin)}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Field
                  key="ssn"
                  id="ssn"
                  name="ssn"
                  component={returnField('text')}
                  label={t('views.appointmentLogin.socialSecurityNumber')}
                  type="text"
                  fullWidth
                  validate={[required]}
                  placeholder="PPKKVV-XXXX"
                  required
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Field
                  key="bookingref"
                  id="bookingref"
                  name="bookingref"
                  component={returnField('text')}
                  label={t('views.appointmentLogin.bookingReferenceId')}
                  type="text"
                  fullWidth
                  validate={[required]}
                  required
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                {reservationStatus && (
                  <Typography variant="body1" align="left" className={classes.statusMessage}>
                    {reservationStatus}
                  </Typography>
                )}
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} md={3}>
                <Button
                  disableRipple
                  type="submit"
                  color="secondary"
                  variant="contained"
                  className={classes.button}
                  disabled={submitting}>
                  {t('views.appointmentLogin.fetchAppointmentInformation')}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      )}
    </Grid>
  );
};

const mapStateToProps = (state, ownProps) => {
  const reservationDetails = R.path(['appointment', 'reservationDetails'], state);

  return {
    reservationDetails,
    initialValues: {
      bookingref: ownProps.match.params.bookingref,
    },
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  getAppointmentLogin: values =>
    dispatch(getAppointmentLogin(values, R.path(['history'], ownProps), R.path(['lang'], ownProps))),
  clearData: () => dispatch(clearData()),
  clearDataAppointment: () => dispatch(clearDataAppointment()),
  clearDataTracking: () => dispatch(clearDataTracking()),
});

const FormDecoratedComponent = reduxForm({
  form: 'appointmentLogin',
  validate,
  enableReinitialize: true,
  keepDirtyOnReinitialize: false,
  updateUnregisteredFields: true,
})(AppointmentLogin);

export default withStyles(inlineStyles)(
  withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(FormDecoratedComponent)),
);
