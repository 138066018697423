import React, { useEffect } from 'react';
import * as R from 'ramda';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Helmet } from 'react-helmet';

import { connect } from 'react-redux';

import { clearData } from '../../store/actions/availabilityActions';
import { clearDataAppointment } from '../../store/actions/appointmentActions';
import { clearDataTracking } from '../../store/actions/trackingActions';

import { withStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next';

import Service from '../subcomponents/Service';
import RedCta from '../subcomponents/RedCta';

import { getPageTitle } from '../../utils/SEO';

const inlineStyles = theme => ({
  button: {
    width: '100%',
  },
  expansionPanel: {
    width: '100%',
  },
  paddingLeftRight: {
    paddingLeft: theme.spacing.body,
    paddingRight: theme.spacing.body,
    [theme.breakpoints.up('lg')]: {
      padding: 0,
    },
  },
});

/**
 * Landing
 * Use to display the landing page with all the services
 * @param {Object} classes    Classes from JSS inlineStyles
 */

const Landing = ({
  clearData,
  clearDataAppointment,
  clearDataTracking,
  classes,
  t,
  history,
  landingPageContents,
  lang,
  localeLong,
}) => {
  useEffect(() => {
    clearData();
    clearDataAppointment();
    clearDataTracking();
  });

  const services = R.path(['services'], landingPageContents);
  const advertisementSet = R.path(['activeAdvertisement'], landingPageContents) ? true : false;

  return (
    <Grid container direction="row" spacing={0}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{getPageTitle(t('views.landing.reservation'))}</title>
      </Helmet>

      <Grid item xs={12} className={classes.paddingLeftRight}>
        <Typography variant="h1" className={classes.h1}>
          {t('views.chooseService')}
        </Typography>
      </Grid>
      {advertisementSet && (
        <Grid item xs={12} md={4} className={classes.paddingLeftRight} sx={{ display: { xl: 'none', md: 'block' } }}>
          <RedCta
            content={{
              headline: R.path(['activeAdvertisement', 'fields', `titleIn${localeLong}`], landingPageContents),
              price: R.path(['activeAdvertisement', 'fields', 'price'], landingPageContents),
              caption: R.path(['activeAdvertisement', 'fields', `descriptionIn${localeLong}`], landingPageContents),
              targetUrl: R.path(['activeAdvertisement', 'fields', `urlFor${localeLong}Users`], landingPageContents),
            }}
          />
        </Grid>
      )}

      <Grid item container xs={12} md={advertisementSet ? 8 : 12} className={classes.paddingLeftRight}>
        {/** Map out services and their subservices. */}
        {services &&
          services.map(item => {
            const fields = item.fields;

            const subServices = fields.subservices
              ? fields.subservices.map(item => ({
                  grandVisionApiId: item.fields.grandVisionApiId,
                  urlFragment: item.fields.urlFragment,
                  headline: item.fields[[`nameIn${localeLong}`]],
                  caption: item.fields[[`descriptionIn${localeLong}`]],
                  prices: {
                    normalPrice: item.fields.normalPrice,
                    priceLabel: item.fields[[`priceLabelIn${localeLong}`]],
                    campaignPrice: item.fields.campaignPrice,
                  },
                }))
              : [];

            return (
              <Service
                lang={lang}
                key={fields.id}
                id={fields.id}
                history={history}
                headline={fields[`nameIn${localeLong}`]}
                caption={fields[`descriptionIn${localeLong}`]}
                subServices={[...subServices]}
                link={fields[`urlIn${localeLong}`]}
              />
            );
          })}
      </Grid>
      {advertisementSet && (
        <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
          <Grid item xs={12} md={4} className={classes.paddingLeftRight}>
            <RedCta
              content={{
                headline: R.path(['activeAdvertisement', 'fields', `titleIn${localeLong}`], landingPageContents),
                price: R.path(['activeAdvertisement', 'fields', 'price'], landingPageContents),
                caption: R.path(['activeAdvertisement', 'fields', `descriptionIn${localeLong}`], landingPageContents),
                targetUrl: R.path(['activeAdvertisement', 'fields', `urlFor${localeLong}Users`], landingPageContents),
              }}
            />
          </Grid>
        </Box>
      )}
    </Grid>
  );
};

const mapStateToProps = state => {
  return {
    appointmentTypes: R.path(['availability', 'appointment_types'], state),
    thankYouPageInitialLoadComplete: R.path(['contentful', 'thankYouPageInitialLoadComplete'], state),
    errorConnectingContentful: R.path(['contentful', 'errorConnectingContentful'], state),
    localeLong: R.path(['contentful', 'localeLong'], state),
    landingPageContents: R.path(['contentful', 'landingPage'], state),
  };
};

const mapDispatchToProps = dispatch => ({
  clearData: () => dispatch(clearData()),
  clearDataAppointment: () => dispatch(clearDataAppointment()),
  clearDataTracking: () => dispatch(clearDataTracking()),
});

export default withStyles(inlineStyles)(
  withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Landing)),
);
