import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import { withStyles } from '@mui/styles';

const styles = theme => ({
  root: {
    padding: theme.spacing(4),
  },
  error: {
    backgroundColor: 'rgb(253, 236, 234)',
    color: 'rgb(97, 26, 21)',
  },
  iconContainer: {
    paddingRight: theme.spacing(1),
  },
});

const Alert = ({ alertType, classes, className, text }) => {
  return (
    <Grid container className={clsx(classes.root, classes.error, className)} wrap="nowrap">
      <Grid item className={classes.iconContainer}>
        <Typography variant="body2">
          <ErrorOutlineIcon />
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body2">{text}</Typography>
      </Grid>
    </Grid>
  );
};

Alert.propTypes = {
  alertType: PropTypes.oneOf(['error', 'warning', 'info', 'success']),
  classes: PropTypes.object,
  className: PropTypes.string,
  text: PropTypes.string,
};

Alert.defaultProps = {
  alertType: 'error',
  classes: {},
  className: null,
  text: '',
};

export default withStyles(styles)(Alert);
