import { createClient } from 'contentful';

/**
 * Environment variables defined in Netlify
 */
const SPACE_ID = process.env.REACT_APP_CONTENTFUL_SPACE_ID;
const ACCESS_TOKEN = process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN;
const HOST = process.env.REACT_APP_CONTENTFUL_HOST;
const ENVIRONMENT = process.env.REACT_APP_CONTENTFUL_ENVIRONMENT;

const client = createClient({
  space: SPACE_ID,
  accessToken: ACCESS_TOKEN,
  host: HOST,
  environment: ENVIRONMENT,
});

/**
 * Get posts from Contentful
 * @param {string} type       Content model
 * @param {*} amount          Limit
 * @param {*} page            Paging
 */
export const getPosts = (type, amount = 1000, page = 0) => {
  let order = '-sys.createdAt';

  return client.getEntries({
    content_type: type,
    limit: amount,
    skip: amount * page,
    include: 10,
    order,
  })
}

