import React from 'react';
import * as R from 'ramda';
import { Helmet } from 'react-helmet';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import { clearData } from '../../store/actions/availabilityActions';
import { clearDataAppointment, cancelAppointment } from '../../store/actions/appointmentActions';
import { clearDataTracking } from '../../store/actions/trackingActions';

import { getPageTitle } from '../../utils/SEO';

import * as moment from 'moment';

import { connect } from 'react-redux';

import { withStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next';

const inlineStyles = theme => ({
  button: {
    width: '100%',
  },
  expansionPanel: {
    width: '100%',
  },
  paddingLeftRight: {
    paddingLeft: theme.spacing.body,
    paddingRight: theme.spacing.body,
    [theme.breakpoints.up('lg')]: {
      padding: 0,
    },
  },
  h1: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: '15px',
    },
  },
  headline: {
    textTransform: 'uppercase',
  },
  bigger: {
    fontSize: 18,
  },
  biggerWrapper: {
    marginBottom: 20,
  },
  appointmentBox: {
    backgroundColor: 'rgb(242,247,250)',
    paddingLeft: 31,
    paddingRight: 31,
    paddingTop: 25,
    paddingBottom: 25,
    marginBottom: 24,
    textAlign: 'left',
  },
  redOutlined: {
    color: theme.palette.primary.main,
    borderColor: `${theme.palette.primary.main} !important`,
  },
  rowMargin: {
    marginBottom: 20,
  },
});

/**
 * Appointments
 * Use to display a successful fetch appointment result
 * @param {Object} classes    Classes from JSS inlineStyles
 */

const Appointments = ({
  classes,
  t,
  lang,
  history,
  cancelAppointment,
  localeLong,
  reservationDetails,
  appointmentType,
  clearData,
  clearDataAppointment,
  clearDataTracking,
}) => {
  const {
    appointmentDate,
    startTimeShort,
    startTimeParsed,
    appointmentDuration,
    appointmentStore,
    appointmentAddress,
    appointmentUUID,
    opticianName,
  } = reservationDetails;

  const appointmentName = R.path(['fields', `nameIn${localeLong}`], appointmentType);
  const appointmentKey = R.path(['fields', 'urlFragment'], appointmentType);

  let appointmentTime = moment(startTimeParsed);
  const now = moment(new Date());

  let cancelPossible =
    appointmentKey === 'eye-test-and-eye-health-exam'
      ? moment.duration(appointmentTime.diff(now)).asHours() > 12
      : true;

  return (
    <Grid container direction="row" spacing={0} className={classes.paddingLeftRight}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{getPageTitle(t('views.appointmentDetails.myReservation'))}</title>
      </Helmet>
      <Grid item xs={12}>
        <Typography variant="h1" className={classes.h1}>
          {t('views.appointmentLogin.ownReservation')}
        </Typography>
      </Grid>

      <Grid container direction="row" spacing={0} className={classes.appointmentBox}>
        <Grid item xs={12} align="left">
          <Typography className={classes.headline} variant="h5">
            {appointmentName}
          </Typography>
        </Grid>

        <Grid item xs={12} align="left">
          <div className={classes.biggerWrapper}>
            <Typography className={classes.bigger} variant="body1">
              {appointmentDate}
            </Typography>
            <Typography className={classes.bigger} variant="body1">
              {t('dateTime.clock')} {startTimeShort}({t('views.appointmentDetails.duration')} {appointmentDuration}{' '}
              {t('views.appointmentDetails.minutes')})
            </Typography>
          </div>
          <Typography variant="body2">
            <strong>{appointmentStore}</strong>
          </Typography>
          <Typography variant="body2">{appointmentAddress}</Typography>
          <Typography className={classes.rowMargin} variant="body2">
            <strong>{opticianName}</strong>
          </Typography>
          <Typography variant="body2">{cancelPossible && t('views.appointmentDetails.instructions')}</Typography>
        </Grid>
        {cancelPossible && (
          <Grid item xs={12} align="right">
            <Button
              disableRipple
              color="secondary"
              variant="contained"
              component={Box}
              onClick={() => cancelAppointment(appointmentUUID)}>
              <strong>{t('views.appointmentLogin.cancel')}</strong>
            </Button>
          </Grid>
        )}
      </Grid>
      <Grid item xs={12} align="center">
        <Button
          disableRipple
          color="secondary"
          variant="outlined"
          className={classes.redOutlined}
          component={Box}
          onClick={() => history.push(`/${lang}/appointment/logout`)}>
          <strong>{t('views.appointmentLogin.logOut')}</strong>
        </Button>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = state => {
  const reservationDetails = R.path(['appointment', 'reservationDetails'], state);
  const appointmentGATID = R.path(['appointmentGATID'], reservationDetails);
  const subServices = R.path(['contentful', 'subServices'], state);

  const appointmentType = R.head(subServices.items.filter(item => item.fields.grandVisionApiId === appointmentGATID));

  return {
    reservationDetails,
    appointmentType,
    localeLong: R.path(['contentful', 'localeLong'], state),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  cancelAppointment: values =>
    dispatch(cancelAppointment(values, R.path(['history'], ownProps), R.path(['lang'], ownProps))),
  clearData: () => dispatch(clearData()),
  clearDataAppointment: () => dispatch(clearDataAppointment()),
  clearDataTracking: () => dispatch(clearDataTracking()),
});

export default withStyles(inlineStyles)(
  withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Appointments)),
);
