import React, { Component } from 'react';
import * as R from 'ramda';
import 'moment/locale/fi';
import 'moment/locale/sv';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

import { withTranslation } from 'react-i18next';
import { withStyles } from '@mui/styles';

import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import RadioGroup from '@mui/material/RadioGroup';

import InputAdornment from '@mui/material/InputAdornment';

import Radio from '@mui/material/Radio';

/**
 * Overwrite initial input field border style with gray color
 */
const inlineTextStyles = theme => ({});

const renderTextFieldWrapped = ({
  input,
  label,
  helperText,
  disabled,
  type,
  val,
  placeholder,
  required,
  fullWidth,
  meta: { touched, error },
  adornment,
  t,
  classes,
  noBottomMargin,
  lang,
  handleMonthChange,
  handleDaySelect,
  value,
  ...custom
}) => {
  return (
    <span>
      <InputLabel shrink style={{ textAlign: 'left', fontSize: '20px', color: '#000' }}>
        {label} {required && '*'}
      </InputLabel>
      <TextField
        style={{ marginTop: 0 }}
        InputProps={{
          style: { color: disabled ? 'inherit' : 'inherit' },
          classes: {
            notchedOutline: classes.notchedOutline,
            focused: classes.focused,
          },
          endAdornment: adornment && <InputAdornment position="end">{adornment}</InputAdornment>,
        }}
        disabled={disabled}
        onKeyPress={e => {
          // Allow only numeric characters and a comma
          const regexp = /[0-9,]+/;
          if (type === 'number' && !regexp.test(e.key)) {
            e.preventDefault();
          }
        }}
        helperText={helperText}
        fullWidth={fullWidth}
        required={required}
        margin="normal"
        type="text"
        {...input}
        value={val || input.value}
        variant="outlined"
        placeholder={placeholder}
      />
      {touched && error && (
        <Typography variant="body1" style={{ fontSize: 16, color: '#FE2213', textAlign: 'left' }}>
          {t(`validation.${error}`)}
        </Typography>
      )}
      <div style={{ marginBottom: `${noBottomMargin ? '0px ' : '24px'}` }}></div>
    </span>
  );
};

const renderTextField = withStyles(inlineTextStyles)(withTranslation('translations')(renderTextFieldWrapped));

const renderCheckbox = ({
  input,
  label,
  helperText,
  disabled,
  type,
  val,
  required,
  fullWidth,
  meta: { touched, error },
  adornment,
  noMargin,
  ...custom
}) => (
  <div>
    <FormControlLabel
      control={<Checkbox checked={val} disabled={disabled} {...input} />}
      label={label}
      style={!noMargin ? { marginTop: 0, marginBottom: 24 } : {}}
    />
    {error && <span>{error}</span>}
  </div>
);

const renderRadioGroupWrapped = ({
  input,
  label,
  disabled,
  type,
  val,
  placeholder,
  required,
  fullWidth,
  meta: { touched, error },
  adornment,
  t,
  classes,
  defaultValue,
  values,
  lang,
  ...custom
}) => {
  input.value === '' && input.onChange(defaultValue);

  return (
    <div>
      <FormControl component="fieldset" fullWidth={fullWidth} style={{ marginTop: '15px', marginBottom: '25px' }}>
        <FormLabel component="legend" style={{ marginBottom: '5px' }}>
          {label} {required && '*'}
        </FormLabel>
        <RadioGroup
          {...input}
          defaultValue={defaultValue}
          aria-label={label}
          onChange={(event, value) => input.onChange(value)}>
          {values.map(radiobutton => (
            <FormControlLabel
              value={radiobutton.value}
              control={<Radio />}
              label={radiobutton.label}
              key={radiobutton.value}
            />
          ))}
        </RadioGroup>
        {touched && error && (
          <Typography variant="body1" style={{ fontSize: 16, color: '#FE2213', textAlign: 'left' }}>
            {t(`validation.${error}`)}
          </Typography>
        )}
      </FormControl>
    </div>
  );
};

const renderRadioGroup = withStyles(inlineTextStyles)(withTranslation('translations')(renderRadioGroupWrapped));

const renderTextArea = ({
  input,
  label,
  helperText,
  disabled,
  type,
  val,
  required,
  rows,
  fullWidth,
  meta: { touched, error },
  ...custom
}) => (
  <span>
    <InputLabel shrink style={{ textAlign: 'left', fontSize: '20px', color: '#000' }}>
      {label}
    </InputLabel>
    <TextField
      style={{ marginTop: 10, marginBottom: 24 }}
      error={touched && error}
      disabled={disabled}
      InputProps={{ style: { color: disabled ? 'inherit' : 'inherit' } }}
      helperText={helperText}
      multiline
      rows={rows || '4'}
      required={required}
      margin="normal"
      fullWidth={fullWidth}
      variant="outlined"
      {...input}
    />
  </span>
);

const inlineSelectStyles = theme => ({});

class renderSelectFieldWrapped extends Component {
  // eslint-disable-line
  state = {
    selected: '',
  };
  handleSelectChange = event => {
    this.setState({ selected: event.target.value });
  };
  render() {
    const {
      input,
      label,
      disabled,
      required,
      fullWidth,
      selOpts,
      optionsAreObjects,
      multiple,
      placeholder,
      onOpen,
      meta: { touched, error },
      t,
      translateOptions,
      classes,
      dataFetchInProgress,
    } = this.props;

    const { selected } = this.state;

    return (
      <div>
        <InputLabel shrink style={{ textAlign: 'left', fontSize: '20px' }}>
          {label} {required && '*'}
        </InputLabel>
        <FormControl fullWidth={fullWidth} style={{ marginBottom: '25px' }}>
          <Select
            className={classes.select}
            classes={{
              input: classes.input,
            }}
            style={{ textAlign: 'left' }}
            variant="outlined"
            value={multiple ? [...selected] : selected}
            disabled={disabled}
            onChange={event => this.handleSelectChange(event)}
            onOpen={
              onOpen &&
              (() => {
                onOpen();
              })
            }
            error={touched && error}
            displayEmpty
            fullWidth
            required={required}
            {...input}
            inputProps={{
              style: {
                padding: 0,
              },
            }}>
            <MenuItem value="">{placeholder ? placeholder : t('views.selectService.select')}</MenuItem>
            {dataFetchInProgress && (
              <div style={{ paddingTop: '12px', textAlign: 'center' }}>
                <CircularProgress className={classes.circularProgress} />
              </div>
            )}
            {R.prop('length', selOpts) > 0 &&
              selOpts.map(item =>
                optionsAreObjects ? (
                  <MenuItem key={item.key} value={JSON.stringify(item)}>
                    {translateOptions ? t(`options.${item.value}`) : item.value}
                  </MenuItem>
                ) : (
                  <MenuItem key={item} value={item}>
                    {translateOptions ? t(`options.${item}`) : item}
                  </MenuItem>
                ),
              )}
          </Select>
        </FormControl>
      </div>
    );
  }
}

const renderSelectField = withStyles(inlineSelectStyles)(withTranslation('translations')(renderSelectFieldWrapped));

const ssncheck = value => {
  const ssnRegExp = /^(0[1-9]|[12]\d|3[01])(0[1-9]|1[0-2])(\d\d-|[01]\dA|2[0-4]A)\d{3}[\dABCDEFHJKLMNPRSTUVWXY]$/g;
  const g_checks = '0123456789ABCDEFHJKLMNPRSTUVWXY';
  const ssnCheck = ssnRegExp.exec(value);

  if (ssnCheck) {
    let tmp1 = g_checks.charAt(parseInt(value.substr(0, 6) + value.substr(7, 3), 10) % 31);
    return tmp1 === value.charAt(10);
  }

  return Array.isArray(ssnCheck) && ssnCheck.length > 0;
};

const validatePhoneNumber = phoneNumber => {
  const phoneRegEx = /^\+[1-9]\d{10,14}$/;
  return phoneRegEx.test(phoneNumber);
};

const validateEmail = email => {
  const emailRegEx = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
  return emailRegEx.test(email);
};

/**
 * Submit validation
 * @param {} values
 */
export const validate = values => {
  const errors = {};
  const required = ['firstName', 'lastName', 'phoneNumber', 'email', 'personalID', 'preferredChannelOfCommunication'];
  const ssnrequired = ['personalID'];
  const phonerequired = ['phoneNumber'];
  const emailrequired = ['email'];

  required.forEach(field => {
    if (!values[field]) {
      errors[field] = 'required';
    }
  });

  ssnrequired.forEach(field => {
    if (!ssncheck(values[field])) {
      errors[field] = 'ssnrequired';
    }
  });

  phonerequired.forEach(field => {
    if (!validatePhoneNumber(values[field])) {
      errors[field] = 'phonerequired';
    }
  });

  emailrequired.forEach(field => {
    if (!validateEmail(values[field])) {
      errors[field] = 'emailrequired';
    }
  });

  return errors;
};

export const returnField = fieldType => {
  switch (fieldType) {
    case 'number':
    case 'text':
    case 'date':
      return renderTextField;
    case 'checkbox':
      return renderCheckbox;
    case 'radiogroup':
      return renderRadioGroup;
    case 'textarea':
      return renderTextArea;
    case 'select':
      return renderSelectField;
    default:
      return renderTextField;
  }
};
