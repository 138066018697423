import React from 'react';
import { Helmet } from 'react-helmet';

const GoogleTagManager = () => {
  const tagManagerId = process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID;

  return (
    <>
      <Helmet>
        <script>
          {(function (s, l, i) {
            if (typeof window !== 'undefined' && typeof document !== 'undefined') {
              window[l] = window[l] || [];
              window[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
              const f = document.getElementsByTagName(s)[0];
              const j = document.createElement(s);
              const dl = l !== 'dataLayer' ? `&l=${l}` : '';
              j.async = true;
              j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`;
              f.parentNode.insertBefore(j, f);
            }
          })('script', 'dataLayer', tagManagerId)}
        </script>
      </Helmet>
      <noscript>
        <iframe
          title="gtag"
          src={`https://www.googletagmanager.com/ns.html?id=${tagManagerId}`}
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
        />
      </noscript>
    </>
  );
};

GoogleTagManager.propTypes = {};

GoogleTagManager.defaultProps = {};

export default GoogleTagManager;
