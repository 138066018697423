import * as R from 'ramda';

import {
  GET_GEOLOCATION,
  GET_GEOLOCATION_FAILURE,
  GET_CLOSESTSHOPTOCUSTOMER
} from '../actions/geolocationActions';

const initialState = {
  geolocation: {},
  geoLocationFetchComplete: false,
  geoLocationFetchError: false
};

export default function geolocationReducer(state = initialState, action) {
  switch (action.type) {
    case GET_GEOLOCATION:

      return {
        ...state,
        geolocation: R.path(['results'], action),
        geoLocationFetchComplete: true,
        geoLocationFetchError: false 
      };
    case GET_GEOLOCATION_FAILURE:
      return {
        ...state,
        geolocation: R.path(['results'], action),
        geoLocationFetchComplete: true,
        geoLocationFetchError: true 
      };
    case GET_CLOSESTSHOPTOCUSTOMER:
      return {
        ...state,
        closestShopToCustomer: R.head(R.path(['closestStore'], action)),
      }
    default:
      return state;
  }
}
