import * as R from 'ramda';
import i18n from 'i18next';

import {
  GET_DATETIME,
  GET_DATETIME_FAILURE,
  GET_AVAILABILITY_IN_PROGRESS,
  GET_AVAILABILITY,
  GET_AVAILABILITY_FAILURE,
  GET_AVAILABILITY_MULTIPLE_STORES,
  GET_AVAILABILITY_MULTIPLE_STORES_FAILURE,
  GET_SPECIALISTS,
  GET_SPECIALISTS_IN_PROGRESS,
  GET_SPECIALISTS_FAILURE,
  MAKE_RESERVATION,
  MAKE_RESERVATION_FAILURE,
  SELECT_SERVICE,
  SELECT_TIMESLOT,
  CLEAR_DATA,
  CLEAR_AVAILABILITY,
  CLEAR_FETCH_TIMESTAMP,
  CLEAR_DATA_AVAILABILITY,
  CLEAR_MONTH_AVAILABILITY,
  CLEAR_AVAILABLE_OPTICIANS,
  GET_APPOINTMENTTYPES,
  GET_APPOINTMENTTYPES_FAILURE,
  GET_ALL_STORES,
  GET_ALL_STORES_FAILURE,
  GET_AVAILABILITY_BASED_ON_A_SPECIALIST,
  GET_AVAILABILITY_BASED_ON_A_SPECIALIST_FAILURE,
  SET_SERVICE_MOUNTED,
  SET_AVAILABILITY_START_DATE,
} from '../actions/availabilityActions';
import moment from 'moment';

const initialState = {
  serviceMounted: false,
  service: '',
  subService: '',
  subServiceUrlFragment: '',
  subServiceDescription: '',
  storesWhereAvailable: null,
  grandVisionApiId: null,
  timeSlot: '',
  endTime: '',
  selectedStoreId: '',
  selectedOptician: '',
  selectedOpticianName: '',
  timeSlotFormatted: '',
  opticiansAvailable: [],
  price: {},
  day: '',
  storeAddress: '',
  storeName: '',
  appointmentType: '',
  appointment_types: [],
  available_time_slots: [],
  stores: [],
  currentTime: new Date(),
  availabilityCallInProgress: false,
  specialistCallInProgress: false,
  fetchTimeStamp: null,
  serverFailure: false,
  appointmentTypesInitialLoadComplete: false,
  dateTimeInitialLoadComplete: false,
  fetchedMonthAvailabilities: [],
  availabilityStartDate: moment().format('YYYY-MM-DD'),
};

export default function availabilityReducer(state = initialState, action) {
  const lang = i18n.language;

  const sortAlphabetically = arr => {
    return arr.sort();
  };

  switch (action.type) {
    case GET_DATETIME:
      return {
        ...state,
        currentTime: R.path(['results'], action),
        dateTimeInitialLoadComplete: true,
        serverFailure: false,
      };
    case GET_DATETIME_FAILURE:
      return {
        ...state,
        serverFailure: true,
      };
    case GET_APPOINTMENTTYPES:
      return {
        ...state,
        appointment_types: R.path(['results', 'data'], action),
        appointmentTypesInitialLoadComplete: true,
        serverFailure: false,
      };
    case GET_APPOINTMENTTYPES_FAILURE:
      return {
        ...state,
        serverFailure: true,
      };
    case GET_AVAILABILITY_IN_PROGRESS:
      return {
        ...state,
        availabilityCallInProgress: true,
      };
    case CLEAR_AVAILABILITY:
      return {
        ...state,
        available_time_slots: [],
      };

    case CLEAR_FETCH_TIMESTAMP:
      return {
        ...state,
        fetchTimeStamp: null,
      };

    case GET_AVAILABILITY_BASED_ON_A_SPECIALIST:
      let dataFilteredWithSpecialist = [];
      let specialists = [];

      const resWithSpecialist = R.path(['results'], action).map(item => {
        // Add store to optician object to help filtering them later
        const opticiansWithStores = item.opticians.map(optician => {
          return {
            ...optician,
            storeId: item.storeId,
          };
        });

        // Concat all opticians together
        specialists = specialists.concat(opticiansWithStores);

        return item.data.map(slot => {
          const timeSlots = slot.attributes.timeSlots;

          // Add storeId direct to timeslot to help filtering the slots based on store later.
          const timeSlotsEnriched = timeSlots.map(timeSlot => ({
            ...timeSlot,
            storeId: item.storeId,
            appointmentType: item.appointmentType,
            key: `${item.storeId}${timeSlot.startTime}`,
          }));

          // Reset attributes.timeSlots with the enriched timeslots
          const timeSlotLens = R.lensPath(['attributes', 'timeSlots']);
          const slotEnriched = R.set(timeSlotLens, timeSlotsEnriched, slot);

          // Return the newly constructed new object
          return {
            ...slotEnriched,
            storeId: item.storeId,
            appointmentType: item.appointmentType,
          };
        });
      });

      // Construct specialists object
      specialists = specialists.map(item => ({
        storeId: R.path(['storeId'], item),
        key: R.path(['id'], item),
        value: `${R.path(['attributes', 'firstName'], item)} ${R.path(['attributes', 'lastName'], item)}, ${R.path(
          ['attributes', 'title'],
          item,
        )}`,
      }));

      return {
        ...state,
        available_time_slots: dataFilteredWithSpecialist.concat(...resWithSpecialist),
        opticiansAvailable: specialists,
        availabilityCallInProgress: false,
        serverFailure: false,
      };

    case GET_AVAILABILITY_BASED_ON_A_SPECIALIST_FAILURE:
      return {
        ...state,
        serverFailure: true,
      };

    case GET_AVAILABILITY:
      return {
        ...state,
        available_time_slots: R.path(['results', 'data'], action),
        availabilityCallInProgress: false,
        serverFailure: false,
      };

    case GET_AVAILABILITY_FAILURE:
      return {
        ...state,
        serverFailure: true,
        availabilityCallInProgress: false,
      };

    case GET_AVAILABILITY_MULTIPLE_STORES:
      let data = [];
      let specialists_multiple_stores = [];

      const res = R.path(['results'], action).map(item => {
        // Enhance optician object with storeId
        const opticiansWithStores = item.opticians.map(optician => {
          return {
            ...optician,
            storeId: item.storeId,
          };
        });

        // Concat opticians together
        specialists_multiple_stores = specialists_multiple_stores.concat(opticiansWithStores);

        return item.data.map(slot => {
          const timeSlots = slot.attributes.timeSlots;

          // Add storeId direct to timeslot to help filtering the slots based on store later.
          const timeSlotsEnriched = timeSlots.map(timeSlot => ({
            ...timeSlot,
            storeId: item.storeId,
            appointmentType: item.appointmentType,
            key: `${item.storeId}${timeSlot.startTime}`,
          }));

          // Reset attributes.timeSlots with the enriched timeslots
          const timeSlotLens = R.lensPath(['attributes', 'timeSlots']);
          const slotEnriched = R.set(timeSlotLens, timeSlotsEnriched, slot);

          // Return the newly constructed new object
          return {
            ...slotEnriched,
            storeId: item.storeId,
            appointmentType: item.appointmentType,
          };
        });
      });

      // Construct opticians object
      specialists_multiple_stores = specialists_multiple_stores.map(item => ({
        storeId: R.path(['storeId'], item),
        key: R.path(['id'], item),
        value: `${R.path(['attributes', 'firstName'], item)} ${R.path(['attributes', 'lastName'], item)}, ${R.path(
          ['attributes', 'title'],
          item,
        )}`,
      }));

      let formattedMonthYear = [moment(R.path(['availabilityStartDate'], action)).format('MM-YYYY')];

      return {
        ...state,
        available_time_slots: data.concat(...res, R.path(['available_time_slots'], state)),
        availabilityStartDate: R.path(['availabilityStartDate'], action),
        fetchedMonthAvailabilities: data.concat(formattedMonthYear, R.path(['fetchedMonthAvailabilities'], state)),
        opticiansAvailable:
          specialists_multiple_stores.length > 0 ? specialists_multiple_stores : R.path(['opticiansAvailable'], state),
        availabilityCallInProgress: false,
        serverFailure: false,
        fetchTimeStamp: Date(),
      };

    case GET_SPECIALISTS:
      let specialistsArr = [];

      const { opticians, storeIds } = R.path(['results'], action);

      opticians.forEach((store, index) => {
        store.data.forEach(optician => {
          const attributes = optician.attributes;
          specialistsArr.push({
            key: optician.id,
            value: `${attributes.firstName} ${attributes.lastName}, ${attributes.title}`,
            storeId: storeIds[index],
          });
        });
      });

      return {
        ...state,
        opticiansAvailable: specialistsArr,
        specialistCallInProgress: false,
        serverFailure: false,
        fetchTimeStamp: Date(),
      };

    case GET_SPECIALISTS_IN_PROGRESS:
      return {
        ...state,
        specialistCallInProgress: true,
      };
    case GET_AVAILABILITY_MULTIPLE_STORES_FAILURE:
    case GET_SPECIALISTS_FAILURE:
      return {
        ...state,
        serverFailure: true,
        availabilityCallInProgress: false,
        specialistCallInProgress: false,
      };

    case GET_ALL_STORES:
      const stores = R.path(['results', 'data'], action);

      const urlReplaceNordicChars = string => {
        return string
          .replace(/ä/g, 'a')
          .replace(/Ä/g, 'A')
          .replace(/ö/g, 'o')
          .replace(/Ö/g, 'O')
          .replace(/å/g, 'a')
          .replace(/Å/g, 'A')
          .replace(/ /g, '-')
          .replace(/,/g, '-');
      };

      // Add url format to store object

      const storesEnhanced = stores.map(item => {
        let storeName = item.attributes.name[lang];
        return {
          ...item,
          url: urlReplaceNordicChars(storeName).toLowerCase(),
          city: item.attributes.address.city,
        };
      });

      const filteredStores = stores.filter(store => R.path(['attributes', 'address', 'city'], store) !== '_');

      let cities =
        filteredStores && R.uniq(filteredStores.map(item => R.path(['attributes', 'address', 'city'], item)));

      cities = sortAlphabetically(cities);

      let storesGroupedByCities = {};
      cities.map(city => {
        const storesWithinACity = R.filter(
          store => R.toLower(R.path(['attributes', 'address', 'city'], store)) === R.toLower(city),
          storesEnhanced,
        );
        return (storesGroupedByCities[city] = storesWithinACity);
      });

      let storesGroupedByCitiesByUrlKey = {};
      cities.map(city => {
        const storesWithinACity = R.filter(
          store => R.toLower(R.path(['attributes', 'address', 'city'], store)) === R.toLower(city),
          storesEnhanced,
        );
        return (storesGroupedByCitiesByUrlKey[urlReplaceNordicChars(city).toLowerCase()] = storesWithinACity);
      });

      const storesLocations =
        R.path(['results', 'data'], action) &&
        R.path(['results', 'data'], action).map(item => {
          return {
            latitude: R.path(['attributes', 'location', 'latitude'], item),
            longitude: R.path(['attributes', 'location', 'longitude'], item),
            storeId: R.path(['id'], item),
            city: R.path(['attributes', 'address', 'city'], item),
          };
        });

      return {
        ...state,
        stores: storesEnhanced,
        cities: cities,
        storesGroupedByCities: storesGroupedByCities,
        storesGroupedByCitiesByUrlKey: storesGroupedByCitiesByUrlKey,
        storesLocations: storesLocations,
        serverFailure: false,
      };

    case GET_ALL_STORES_FAILURE:
      return {
        ...state,
        serverFailure: true,
      };

    case MAKE_RESERVATION:
      return {
        ...state,
        serverFailure: false,
      };

    case MAKE_RESERVATION_FAILURE:
      return {
        ...state,
        serverFailure: true,
      };
    case SELECT_SERVICE:
      return {
        ...state,
        service: R.path(['values', 'service'], action),
        subService: R.path(['values', 'subService'], action),
        subServiceUrlFragment: R.path(['values', 'subServiceUrlFragment'], action),
        subServiceDescription: R.path(['values', 'subServiceDescription'], action),
        storesWhereAvailable: R.path(['values', 'storesWhereAvailable'], action),
        grandVisionApiId: R.path(['values', 'grandVisionApiId'], action),
        price: R.path(['values', 'price'], action),
      };
    case SELECT_TIMESLOT:
      return {
        ...state,
        timeSlot: R.path(['values', 'timeSlot'], action),
        endTime: R.path(['values', 'endTime'], action),
        selectedStoreId: R.path(['values', 'selectedStoreId'], action),
        timeSlotFormatted: R.path(['values', 'timeSlotFormatted'], action),
        storeAddress: R.path(['values', 'storeAddress'], action),
        storeName: R.path(['values', 'storeName'], action),
        selectedOptician: R.path(['values', 'selectedOptician'], action),
        selectedOpticianName: R.path(['values', 'selectedOpticianName'], action),
        day: R.path(['values', 'day'], action),
        appointmentType: R.path(['values', 'appointmentType'], action),
      };
    case CLEAR_DATA:
      return {
        ...state,
        service: '',
        subService: '',
        subServiceUrlFragment: '',
        subServiceDescription: '',
        timeSlot: '',
        endTime: '',
        selectedStoreId: '',
        selectedOptician: '',
        selectedOpticianName: '',
        timeSlotFormatted: '',
        day: '',
        storeAddress: '',
        storeName: '',
        available_time_slots: [],
        opticiansAvailable: [],
        appointmentType: '',
        price: {},
        serverFailure: false,
        storesWhereAvailable: null,
        grandVisionApiId: null,
        fetchedMonthAvailabilities: [],
        availabilityStartDate: moment().format('YYYY-MM-DD'),
      };
    case CLEAR_DATA_AVAILABILITY:
      return initialState;

    case CLEAR_AVAILABLE_OPTICIANS:
      return {
        ...state,
        opticiansAvailable: [],
        selectedOptician: '',
        selectedOpticianName: '',
      };
    case CLEAR_MONTH_AVAILABILITY:
      return {
        ...state,
        fetchedMonthAvailabilities: [],
      };
    case SET_SERVICE_MOUNTED:
      return {
        ...state,
        serviceMounted: true,
      };
    case SET_AVAILABILITY_START_DATE:
      const now = moment();
      const newStartDate = moment(R.path(['values'], action));
      const startDate = now > newStartDate ? now : newStartDate;
      return {
        ...state,
        availabilityStartDate: startDate,
      };
    default:
      return state;
  }
}
