let brandColor = '#27BDBE';
let white = '#FFFFFF';

export const colors = {
  black: 'rgb(0, 0, 0)',
  white: 'rgb(255, 255, 255)',
  palette: {
    primary: {
      light: '#757ce8',
      main: brandColor,
      dark: '#2C4E50',
      contrastText: white,
    },
    secondary: {
      light: white,
      main: '#000',
      dark: brandColor,
      contrastText: white,
    },
    background: {
      paper: white,
      dark: 'rgb(17,17,17)',
    },
    fontColor: {
      body: 'rgb(0, 0, 0)',
    },
  },
  button: {
    contained: {
      backgroundColor: brandColor,
      color: white,
      hover: {
        backgroundColor: white,
        borderColor: brandColor,
        color: brandColor,
      },
    },
    outlined: {
      hover: {
        backgroundColor: brandColor,
        color: white,
      },
    },
    selected: {
      backgroundColor: brandColor,
      color: 'inherit',
      hover: {
        backgroundColor: brandColor,
        color: 'inherit',
      }
    }
  },
};

let primaryFontFamily = 'Tenso, Montserrat, sans-serif';
let secondaryFontFamily = 'Montserrat, sans-serif';

export const typography = {
  fontFamilyBody: secondaryFontFamily,
  fontFamilyTitle: primaryFontFamily,
  desktop: {
    fontSizeBody1: '16px',
    fontSizeBody2: '15px',
  },
  mobile: {
    fontSizeBody1: '15px',
    fontSizeBody2: '14px',
  },
};

export const components = {
  footer: {
    backgroundColor: colors.palette.background.dark,
  },
};
