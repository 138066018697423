import * as R from 'ramda';
import * as moment from 'moment';

export const TRACK_URL_CHANGE = 'TRACK_URL_CHANGE';
export const TRACK_TIME_FILTER = 'TRACK_TIME_FILTER';
export const TRACK_SELECTSERVICE = 'TRACK_SELECTSERVICE';
export const TRACK_AVAILABLETIMES = 'TRACK_AVAILABLETIMES';
export const TRACK_CONFIRMRESERVATION = 'TRACK_CONFIRMRESERVATION';
export const TRACK_THANKYOU = 'TRACK_THANKYOU';
export const TRACK_APPOINTMENTCANCELLATION = 'TRACK_APPOINTMENTCANCELLATION';
export const TRACK_SAVE_URL_FRAGMENTS = 'TRACK_SAVE_URL_FRAGMENTS';
export const CLEAR_DATA_TRACKING = 'CLEAR_DATA_TRACKING';

/**
 * Push to datalayer
 * Checks that you do not push same event twice in a row
 * @param {object} dataLayerObject
 */
const pushToDataLayer = dataLayerObject => {
  if (typeof window !== 'undefined') {
    if (window.dataLayer) {
      if (window.dataLayer.length > 0 && !R.equals(window.dataLayer[window.dataLayer.length - 1], dataLayerObject)) {
        // eslint-disable-next-line no-undef
        dataLayerObject.ecommerce !== undefined && removeEcommerceFromDataLayer();
        window.dataLayer.push(dataLayerObject);
      } else if (window.dataLayer.length === 0) {
        // eslint-disable-next-line no-undef
        window.dataLayer.push(dataLayerObject);
      }
    } else {
      window.dataLayer = [];
      // eslint-disable-next-line no-undef
      window.dataLayer.push(dataLayerObject);
    }
  }
};

/**
 * Remove ecommerce related array items from dataLayer.
 */
const removeEcommerceFromDataLayer = () => {
  if (typeof window !== 'undefined' && window.dataLayer) {
    window.dataLayer.push({
      ecommerce: undefined,
    });
  }
};

const formatPrice = price => price.replace(',', '.').replace(/[^\d.-]+[^\d]+/g, '');

/**
 * Track URL change
 */
export const trackUrlChange = () => dispatch => {
  dispatch({
    type: TRACK_URL_CHANGE,
  });

  pushToDataLayer({
    event: 'virtualPageLoad',
  });
};

/**
 * Track change of time filter, morning
 */
export const trackTimeFilterMorning = () => dispatch => {
  dispatch({
    type: TRACK_TIME_FILTER,
  });
  pushToDataLayer({
    event: 'time-filter',
    time: '07:00-11:00',
  });
};

/**
 * Track change of time filter, morning
 */
export const trackTimeFilterDay = () => dispatch => {
  dispatch({
    type: TRACK_TIME_FILTER,
  });
  pushToDataLayer({
    event: 'time-filter',
    time: '11:00-15:00',
  });
};

/**
 * Track change of time filter, morning
 */
export const trackTimeFilterAfternoon = () => dispatch => {
  dispatch({
    type: TRACK_TIME_FILTER,
  });
  pushToDataLayer({
    event: 'time-filter',
    time: '15:00-18:00',
  });
};

/**
 * Track change of time filter, morning
 */
export const trackTimeFilterEvening = () => dispatch => {
  dispatch({
    type: TRACK_TIME_FILTER,
  });
  pushToDataLayer({
    event: 'time-filter',
    time: '18:00-21:00',
  });
};

/**
 * Track Selected Service
 */
export const trackSelectService = id => (dispatch, getState) => {
  dispatch({
    type: TRACK_SELECTSERVICE,
  });
  pushToDataLayer({
    event: 'service-accordion',
    service: id,
  });
};

/**
 * Track Confirm Reservation
 * Fires when user enters confirm reservation page
 */
export const trackAvailableTimes = availableTimes => (dispatch, getState) => {
  const service = R.path(['tracking', 'service'], getState());
  const city = R.path(['tracking', 'city'], getState());
  const store = R.path(['tracking', 'store'], getState());
  const currentTime = moment(R.path(['availability', 'currentTime', 'currentDate'], getState()));
  const price = R.path(['availability', 'price', 'primaryPrice'], getState());
  const formattedPrice = price ? formatPrice(price) : '';

  let categoryTemplate = `${service}${city && `/${city}`}${store && `/${store}`}${`/startTime`}${price && `/${price}`}`;

  dispatch({
    type: TRACK_AVAILABLETIMES,
  });

  let impressions = !availableTimes
    ? []
    : availableTimes.map((time, index) => {
        let bookedAppointmentTime = moment(time.endTime);
        let daysToAppointment = bookedAppointmentTime.diff(currentTime, 'days');

        let category = categoryTemplate.replace('startTime', moment(time.startTime).format('hh:mm'));

        let formattedTime = {
          brand: process.env.REACT_APP_BRAND,
          category: category,
          dimension15: daysToAppointment,
          id: time.storeId,
          list: 'appointment-booking',
          name: R.path(['availability', 'subServiceUrlFragment'], getState()),
          position: index + 1,
          price: formattedPrice,
        };

        return formattedTime;
      });

  if (impressions.length > 0) {
    pushToDataLayer({
      event: 'impressions',
      ecommerce: {
        impressions: impressions,
      },
    });
  } else {
    pushToDataLayer({
      event: 'no-availability',
    });
  }
};

/**
 * Track Confirm Reservation
 * Fires when user enters confirm reservation page
 */
export const trackConfirmReservation = () => (dispatch, getState) => {
  const currentTime = moment(R.path(['availability', 'currentTime', 'currentDate'], getState()));
  const bookedAppointmentTime = moment(R.path(['availability', 'timeSlot'], getState()));
  const daysToAppointment = bookedAppointmentTime.diff(currentTime, 'days');
  const startTime = moment(R.path(['availability', 'timeSlot'], getState())).format('HH:mm');

  const service = R.path(['tracking', 'service'], getState());
  const city = R.path(['tracking', 'city'], getState());
  const store = R.path(['tracking', 'store'], getState());
  const price = R.path(['availability', 'price', 'primaryPrice'], getState());
  const formattedPrice = price ? formatPrice(price) : '';

  const category = `${service}${city && `/${city}`}${store && `/${store}`}${startTime && `/${startTime}`}${
    price && `/${price}`
  }`;

  dispatch({
    type: TRACK_CONFIRMRESERVATION,
  });
  pushToDataLayer({
    event: 'checkout',
    ecommerce: {
      checkout: {
        products: [
          {
            name: R.path(['availability', 'subServiceUrlFragment'], getState()),
            price: formattedPrice,
            brand: process.env.REACT_APP_BRAND,
            category: category,
            list: 'appointment-booking',
            dimension15: daysToAppointment, // days to appointment
          },
        ],
      },
    },
  });
};

/**
 * Track Thank You Page
 * Fires when user enters thankyou page (reservation done)
 */
export const trackThankYou = status => (dispatch, getState) => {
  if (status === 'FAILED') {
    dispatch({
      type: TRACK_THANKYOU,
    });
    pushToDataLayer({
      event: 'error',
      'api-code': '404',
    });
    return;
  }

  const currentTime = moment(R.path(['availability', 'currentTime', 'currentDate'], getState()));
  const bookedAppointmentTime = moment(R.path(['availability', 'timeSlot'], getState()));
  const daysToAppointment = bookedAppointmentTime.diff(currentTime, 'days');
  const startTime = moment(R.path(['availability', 'timeSlot'], getState())).format('HH:mm');

  const service = R.path(['tracking', 'service'], getState());
  const city = R.path(['tracking', 'city'], getState());
  const store = R.path(['tracking', 'store'], getState());

  const price = R.path(['availability', 'price', 'primaryPrice'], getState());
  const formattedPrice = price ? formatPrice(price) : '';

  const category = `${service}${city && `/${city}`}${store && `/${store}`}${startTime && `/${startTime}`}${
    price && `/${price}`
  }`;

  dispatch({
    type: TRACK_THANKYOU,
  });
  pushToDataLayer({
    event: 'purchase',
    ecommerce: {
      purchase: {
        actionField: {
          id: R.path(['appointment', 'bookedAppointment', 'id'], getState()), // API Booking Ref ID Long
          affiliation: process.env.REACT_APP_BRAND,
          revenue: formattedPrice,
          tax: '0.00',
          shipping: '0.00',
        },
        products: [
          {
            name: R.path(['availability', 'subServiceUrlFragment'], getState()),
            id: R.path(['appointment', 'bookedAppointment', 'id'], getState()), // API Booking Ref ID Long
            price: formattedPrice,
            brand: process.env.REACT_APP_BRAND,
            category: category,
            list: 'appointment-booking', // fixed
            dimension15: daysToAppointment, // days to appointment
          },
        ],
      },
    },
  });
};

/**
 * Track Appointment Cancellation
 */
export const trackAppointmentCancellation = appointmentUUID => dispatch => {
  dispatch({
    type: TRACK_APPOINTMENTCANCELLATION,
  });
  pushToDataLayer({
    event: 'refund',
    ecommerce: {
      refund: {
        actionField: { id: appointmentUUID }, // Booking Reference Long
      },
    },
  });
};

/**
 * Save url fragments of selected service for tracking use
 * @param {object} values         User filled in values
 * @param {object} history        History object for redirecting
 */
export const trackSaveUrlFragments = values => dispatch => {
  dispatch({
    type: TRACK_SAVE_URL_FRAGMENTS,
    values,
  });
};

/**
 * Track layout version
 */
export const trackLayoutVersion = () => {
  pushToDataLayer({
    event: 'version',
    version: 'A',
  });
};

/**
 * Clear user filled data
 */
export const clearDataTracking = () => dispatch => {
  dispatch({
    type: CLEAR_DATA_TRACKING,
  });
};
