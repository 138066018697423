import React from 'react';
import * as R from 'ramda';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next';

import { clearDataAvailability } from '../../store/actions/availabilityActions';
import { clearDataTracking } from '../../store/actions/trackingActions';
import { clearDataAppointment } from '../../store/actions/appointmentActions';

const inlineStyles = theme => ({
  inform: {
    backgroundColor: theme.footer.backgroundColor,
    color: '#fff',
    marginBottom: 20,
    padding: '20px',
  },
  headline: {
    marginBottom: '20px',
  },
});

/**
 * Inform user of server error
 */

const Inform = ({
  classes,
  serverFailureAvailability,
  serverFailureAppointment,
  t,
  lang,
  clearDataAvailability,
  clearDataTracking,
  clearDataAppointment,
}) => {
  return (
    <div>
      {(serverFailureAvailability || serverFailureAppointment) && (
        <Grid
          container
          direction="column"
          spacing={0}
          className={classes.inform}
          alignItems="center"
          justifyContent="center">
          <Typography variant="body1" className={classes.headline} align="center">
            {t('views.inform.problemsWithServer')}
            <br />
            <br />
            {t('views.inform.pleaseTryAgain')}
          </Typography>
          <Button
            disableRipple
            color="secondary"
            variant="contained"
            className={classes.redButton}
            component={Link}
            onClick={() => {
              clearDataAvailability();
              clearDataTracking();
              clearDataAppointment();
            }}
            to={`/${lang}`}>
            <strong>{t('views.inform.tryAgain')}</strong>
          </Button>
        </Grid>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  serverFailureAvailability: R.path(['availability', 'serverFailure'], state),
  serverFailureAppointment: R.path(['appointment', 'serverFailure'], state),
});

const mapDispatchToProps = dispatch => ({
  clearDataAvailability: () => dispatch(clearDataAvailability()),

  clearDataTracking: () => dispatch(clearDataTracking()),
  clearDataAppointment: () => dispatch(clearDataAppointment()),
});

export default withStyles(inlineStyles)(
  withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Inform)),
);
