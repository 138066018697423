import React from 'react';
import * as R from 'ramda';
import { Helmet } from 'react-helmet';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import Button from '@mui/material/Button';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import { withStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next';

import SelectedServiceBox from '../subcomponents/SelectedServiceBox';
import BackButton from '../subcomponents/BackButton';

import { createReservation } from '../../store/actions/appointmentActions';

import { validate, returnField } from '../../utils/FormHelpers';

import { getPageTitle } from '../../utils/SEO';

const inlineStyles = theme => ({
  button: {
    width: '100%',
    fontSize: 14,
    height: 50,
    [theme.breakpoints.up('md')]: {
      height: 58,
    },
  },
  expansionPanel: {
    width: '100%',
  },
  formGrid: {
    paddingLeft: theme.spacing.body,
    paddingRight: theme.spacing.body,
    order: 2,
  },
  paddingLeftRight: {
    paddingLeft: theme.spacing.body,
    paddingRight: theme.spacing.body,
    order: 1,
  },
  content: {
    marginTop: 20,
  },
  note: {
    color: 'rgb(86,99,110)',
    marginTop: 20,
  },
  h3: {
    color: theme.palette.primary.main,
    textAlign: 'left',
  },
  selectedServiceBoxWrapper: {
    order: 2,
    [theme.breakpoints.up('md')]: {
      order: 3,
    },
  },
});

const required = value => (value ? undefined : 'required');

const ssncheck = value => {
  const ssnRegExp = /^(0[1-9]|[12]\d|3[01])(0[1-9]|1[0-2])(\d\d-|[01]\dA|2[0-4]A)\d{3}[\dABCDEFHJKLMNPRSTUVWXY]$/g;
  const g_checks = '0123456789ABCDEFHJKLMNPRSTUVWXY';
  const ssnCheck = ssnRegExp.exec(value);

  if (ssnCheck) {
    let tmp1 = g_checks.charAt(parseInt(value.substr(0, 6) + value.substr(7, 3), 10) % 31);
    return tmp1 === value.charAt(10) ? undefined : 'ssnrequired';
  } else {
    return Array.isArray(ssnCheck) && ssnCheck.length > 0 ? undefined : 'ssnrequired';
  }
};

/**
 * ConfirmReservation
 * User fills in information and on submit the service will confirm reservation
 * @param {Object} classes    Classes from JSS inlineStyles
 */

const ConfirmReservation = ({
  classes,
  handleSubmit,
  createReservation,
  submitting,
  selectedSubService,
  pristine,
  t,
  lang,
  subServiceUrlFragment,
  brandSettings,
}) => {
  let appointmentPhoneNumber = '';

  if (R.path(['footer', 'fields'], brandSettings)) {
    appointmentPhoneNumber = R.path(['footer', 'fields', 'appointmentPhoneNumber'], brandSettings);
  }

  return (
    <Grid container direction="row" spacing={0}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{getPageTitle(t('views.confirmReservation.userDetails'))}</title>
      </Helmet>
      <Grid item xs={12} className={classes.paddingLeftRight}>
        <Typography variant="h1" className={classes.h1}>
          {t('views.confirmReservation.confirm')}
        </Typography>
      </Grid>
      <Grid item xs={12} md={4} className={classes.selectedServiceBoxWrapper}>
        <SelectedServiceBox content={selectedSubService} />
      </Grid>
      <Grid item xs={12} md={8} className={classes.formGrid}>
        <Typography variant="h3" className={classes.h3}>
          {t('views.confirmReservation.userDetails')}
        </Typography>
        <form className={classes.content} onSubmit={handleSubmit(createReservation)}>
          <Grid container>
            <Grid item xs={12} md={7}>
              <Field
                key="firstName"
                id="firstName"
                name="firstName"
                component={returnField('text')}
                label={t('views.confirmReservation.firstName')}
                type="text"
                fullWidth
                validate={[required]}
                required
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={7}>
              <Field
                key="lastName"
                id="lastName"
                name="lastName"
                component={returnField('text')}
                label={t('views.confirmReservation.lastName')}
                type="text"
                fullWidth
                validate={[required]}
                required
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={7}>
              <Field
                key="personalID"
                id="personalID"
                name="personalID"
                component={returnField('text')}
                label={t('views.confirmReservation.personalID')}
                type="text"
                fullWidth
                validate={[ssncheck]}
                placeholder="PPKKVV-XXXX"
                helperText={t('views.confirmReservation.personalIDInformation') + appointmentPhoneNumber + '.'}
                required
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={4}>
              <Field
                key="phoneNumber"
                id="phoneNumber"
                name="phoneNumber"
                component={returnField('text')}
                label={t('views.confirmReservation.phoneNumber')}
                type="text"
                placeholder="+35812345678"
                fullWidth
                validate={[required]}
                required
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={7}>
              <Field
                key="email"
                id="email"
                name="email"
                component={returnField('text')}
                label={t('views.confirmReservation.email')}
                type="text"
                fullWidth
                validate={[required]}
                required
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <Field
                name="preferredChannelOfCommunication"
                component={returnField('radiogroup')}
                defaultValue="EMAIL"
                required
                validate={[required]}
                label={t('views.confirmReservation.notificationSelection')}
                values={[
                  {
                    value: 'EMAIL',
                    label: t('views.confirmReservation.byEmail'),
                  },
                  {
                    value: 'SMS',
                    label: t('views.confirmReservation.bySMS'),
                  },
                ]}></Field>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Button
                disableRipple
                type="submit"
                color="secondary"
                variant="contained"
                className={classes.button}
                disabled={submitting}>
                <strong>{t('views.confirmReservation.confirm')}</strong>
              </Button>
            </Grid>
          </Grid>
        </form>
        <Typography variant="body1" className={classes.note}>
          {t('views.confirmReservation.thisIsTheLastPhase')}
        </Typography>
        <BackButton to={`/${lang}/${subServiceUrlFragment}`} />
      </Grid>
    </Grid>
  );
};

const mapStateToProps = state => {
  return {
    selectedSubService: {
      headline: R.path(['availability', 'subService'], state),
      dateBigger: R.path(['availability', 'day'], state),
      timeBigger: R.path(['availability', 'timeSlotFormatted'], state),
      address: R.path(['availability', 'storeAddress'], state),
      person: R.path(['availability', 'selectedOpticianName'], state),
      price: R.path(['availability', 'price'], state),
      startTime: R.path(['availability', 'startTime'], state),
      endTime: R.path(['availability', 'endTime'], state),
      timeSlot: R.path(['availability', 'timeSlot'], state),
      storeName: R.path(['availability', 'storeName'], state),
    },
    subServiceUrlFragment: R.path(['availability', 'subServiceUrlFragment'], state),
    brandSettings: R.path(['contentful', 'layout'], state),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  createReservation: values =>
    dispatch(createReservation(values, R.path(['history'], ownProps), R.path(['lang'], ownProps))),
});

const FormDecoratedComponent = reduxForm({
  form: 'selectService',
  validate,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  updateUnregisteredFields: true,
})(ConfirmReservation);

export default withStyles(inlineStyles)(
  withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(FormDecoratedComponent)),
);
