import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import * as R from 'ramda';
import { store } from './store/store';
import { withTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import get from 'lodash/get';

import './App.scss';

import {
  getServices,
  getSubServices,
  getThankYouPage,
  getLandingPage,
  getRedirections,
  saveLocale,
  getLayout,
  saveUsedRedirection,
} from './store/actions/contentfulActions';
import { getClosestShopToCustomer } from './store/actions/geolocationActions';
import {
  getDateTime,
  getAppointmentTypes,
  selectService,
  setServiceMounted,
} from './store/actions/availabilityActions';
import { trackLayoutVersion, trackSaveUrlFragments, trackUrlChange } from './store/actions/trackingActions';

import { create } from 'jss';
import { createGenerateClassName, jssPreset, StylesProvider } from '@mui/styles';
import CircularProgress from '@mui/material/CircularProgress';

import Navigation from './components/partials/Navigation';
import Footer from './components/partials/Footer';
import GoogleTagManager from './components/partials/GoogleTagManager';

import Inform from './components/partials/Inform';

import Landing from './components/views/Landing';
import SelectService from './components/views/SelectService';
import ConfirmReservation from './components/views/ConfirmReservation';
import ThankYou from './components/views/ThankYou';
import Error from './components/views/Error';
import AppointmentLogin from './components/views/AppointmentLogin';
import Appointments from './components/views/Appointments';

import ScrollToTop from './utils/ScrollToTop';

import InfoBanner from './components/subcomponents/InfoBanner';

/**
 * JSS Settings
 */
const generateClassName = createGenerateClassName({
  productionPrefix: 'c',
});
const jss = create({
  ...jssPreset(),
  insertionPoint: 'jss-insertion-point',
});

/**
 * Routing blockers
 * Will prevent user from accessing parts of application if user has no authorization to do so.
 * e.g. Do not allow user to navigate to /thankyou if forms have not been filled and no time has been reserved.
 */

const allowConfirmReservation = () => {
  const storeState = store.getState();

  // Appointment with status is already present so booking is in progress
  if (R.path(['appointment', 'status'], storeState) !== '') {
    return false;
  }

  return R.prop('length', R.path(['availability', 'service'], storeState)) > 0 &&
    R.prop('length', R.path(['availability', 'subService'], storeState)) > 0 &&
    R.path(['availability', 'timeSlot'], storeState)
    ? true
    : false;
};

const formRedirectionUrl = (props, componentProps) => {
  const { saveUsedRedirection } = componentProps;

  const storeState = store.getState();

  const redirections = R.path(['contentful', 'redirections'], storeState);
  const localeLong = R.path(['contentful', 'localeLong'], storeState);

  const pathSplit = props.location.pathname.split('/');
  const slug = pathSplit.pop();
  let redirect = slug;

  for (let i = 0; i < redirections.length; i++) {
    if (get(redirections, [i, `fromIn${localeLong}`], get(redirections, [i, 'fromInFinnish'])) === slug) {
      redirect = get(redirections, [i, `toIn${localeLong}`], get(redirections, [i, 'toInFinnish']));
      saveUsedRedirection(redirections[i]);
      break;
    }
  }

  pathSplit.push(redirect);

  return pathSplit.join('/');
};

const redirectionCheck = props => {
  const storeState = store.getState();

  const redirections = R.path(['contentful', 'redirections'], storeState);

  const pathSplit = props.location.pathname.split('/');
  const slug = pathSplit.pop();
  const localeLong = R.path(['contentful', 'localeLong'], storeState);

  return redirections.some(el => get(el, `fromIn${localeLong}`, get(el, 'fromInFinnish')) === slug);
};

const allowAppointments = () => {
  const storeState = store.getState();

  return R.path(['appointment', 'reservationDetails', 'appointmentUUID'], storeState) ? true : false;
};

const allowCancelled = () => {
  const storeState = store.getState();

  return R.path(['appointment', 'reservationDetails', 'status'], storeState) === 'Cancelled' ? true : false;
};

const allowThankYou = () => {
  const storeState = store.getState();

  return R.path(['appointment', 'status'], storeState) !== '' ? true : false;
};

/**
 * Allow user to land into /:slug
 * Check does that kind of service exist
 * If yes, fire a selectService action and let user go through
 * @param {obj} props
 * @param {obj} componentProps
 */
const allowBook = (props, componentProps) => {
  const { selectService, trackSaveUrlFragments } = componentProps;

  const storeState = store.getState();

  const services = R.path(['contentful', 'landingPage', 'services'], storeState);

  let subServices = [];

  services &&
    services.forEach(service => {
      let chainSubservices = R.path(['fields', 'subservices'], service);
      chainSubservices &&
        chainSubservices.forEach(subservice => {
          subServices.push(subservice);
        });
    });

  const params = R.path(['match', 'params'], props);

  // Check if there is a service with the given slug
  const selectedService = subServices && subServices.filter(item => item.fields.urlFragment === params.slug);
  if (!selectedService) return false;

  // Appointment with status is already present so booking is in progress -> No access
  if (R.path(['appointment', 'status'], storeState) !== '') {
    return false;
  }

  // Check if url fragments for city and store exist or not
  const selectedCity = R.path(['city'], params);
  const selectedStore = R.path(['store'], params);
  const storesGroupedByCitiesByUrlKey = R.path(['availability', 'storesGroupedByCitiesByUrlKey'], storeState);
  const hasCity = selectedCity && R.has(selectedCity, storesGroupedByCitiesByUrlKey);
  const hasStore =
    selectedStore &&
    R.path([selectedCity], storesGroupedByCitiesByUrlKey) &&
    R.path([selectedCity], storesGroupedByCitiesByUrlKey).filter(item => item.url === selectedStore);

  trackSaveUrlFragments({
    service: params.slug ? params.slug : '',
    city: params.city ? params.city : '',
    store: params.store ? params.store : '',
  });

  // If selectedService proves to exist -> now we should just let user pass to the component
  // The book component will then follow the url and fire the appropriate actions

  if (selectedService.length > 0) {
    const servicematch = services.filter(item => {
      const subitemmatch = item.fields.subservices
        ? item.fields.subservices.filter(subitem => subitem.fields.urlFragment === params.slug)
        : '';
      return subitemmatch.length > 0 ? true : false;
    });

    const localeLong = R.path(['contentful', 'localeLong'], storeState);

    const subserviceTitle = R.path(['fields', `nameIn${localeLong}`], R.head(selectedService));
    const subserviceDescription = R.path(['fields', `descriptionIn${localeLong}`], R.head(selectedService));

    const normalPrice = R.path(['fields', 'normalPrice'], R.head(selectedService));
    const campaignPrice = R.path(['fields', 'campaignPrice'], R.head(selectedService));

    const subservicePrice = {
      primaryPrice: campaignPrice ? campaignPrice : normalPrice,
      secondaryPrice: campaignPrice ? normalPrice : '',
      priceLabel: R.path(['fields', `priceLabelIn${localeLong}`], R.head(selectedService)),
    };

    selectService({
      service: R.head(servicematch).fields.id,
      subService: subserviceTitle,
      subServiceUrlFragment: R.head(selectedService).fields.urlFragment,
      subServiceDescription: subserviceDescription,
      price: subservicePrice,
      grandVisionApiId: R.head(selectedService).fields.grandVisionApiId,
      storesWhereAvailable: R.head(selectedService).fields.storesWhereAvailable,
    });

    // Decide if the url format matches city and store data or not and navigate based on it
    if (!selectedCity && !selectedStore) {
      return true;
    }
    if (selectedCity && !selectedStore) {
      return hasCity ? true : false;
    }
    if (selectedCity && selectedStore) {
      return hasCity && hasStore.length > 0 ? true : false;
    }

    return true;
  } else {
    return false;
  }
};

const changeLanguage = (lng, i18n, saveLocale) => {
  i18n.changeLanguage(lng);
  saveLocale(i18n.language);
};

trackLayoutVersion();

/**
 * App
 * Main component that holds:
 * StyleProvider for JSS support
 * BrandTheme for Material UI theming
 * Application structure (navigation, centering container, main container, footer)
 * Scroll to top on route change provided by ScrollToTop
 * Router for application routes
 */

export class App extends Component {
  UNSAFE_componentWillMount() {
    const { trackUrlChange, history } = this.props;
    this.unlisten = history.listen((location, action) => {
      trackUrlChange();
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  componentDidMount() {
    const {
      setServiceMounted,
      getDateTime,
      getServices,
      getSubServices,
      getThankYouPage,
      getLandingPage,
      getRedirections,
      getClosestShopToCustomer,
      getAppointmentTypes,
      getLayout,
    } = this.props;

    setServiceMounted();
    getServices();
    getSubServices();
    getThankYouPage();
    getLandingPage();
    getRedirections();
    getClosestShopToCustomer();
    getAppointmentTypes();
    getDateTime();
    getLayout();
  }

  render() {
    const {
      serviceMounted,
      setServiceMounted,
      getDateTime,
      getServices,
      getSubServices,
      getThankYouPage,
      getLandingPage,
      getRedirections,
      getClosestShopToCustomer,
      getAppointmentTypes,
      getLayout,
    } = this.props;

    if (!serviceMounted) {
      setServiceMounted();
      getServices();
      getSubServices();
      getThankYouPage();
      getLandingPage();
      getRedirections();
      getClosestShopToCustomer();
      getAppointmentTypes();
      getDateTime();
      getLayout();
    }

    const {
      servicesInitialLoadComplete,
      subServicesInitialLoadComplete,
      thankYouPageInitialLoadComplete,
      redirectionInitialLoadComplete,
      errorConnectingContentful,
      appointmentTypesInitialLoadComplete,
      layoutInitialLoadComplete,
      landingPageInitialLoadComplete,
      location,
      t,
      i18n,
      history,
      saveLocale,
    } = this.props;

    const getLocale = () => i18n.language;

    const lang = location.pathname.split('/')[1];

    if (['fi', 'en', 'sv'].includes(lang) && lang !== getLocale()) {
      changeLanguage(lang, i18n, saveLocale);
    }

    saveLocale(i18n.language);

    return (
      <StylesProvider jss={jss} generateClassName={generateClassName}>
        {layoutInitialLoadComplete && (
          <>
            <GoogleTagManager />
            {process.env.REACT_APP_BRAND === 'nissen' && (
              <Helmet>
                <link rel="stylesheet" href="https://use.typekit.net/tao4ddl.css" />
                <link rel="preconnect" href="https://fonts.googleapis.com" />
                <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
                <link
                  href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,700;1,400;1,700&display=swap"
                  rel="stylesheet"
                />
              </Helmet>
            )}
            {process.env.REACT_APP_BRAND === 'keops' && (
              <Helmet>
                <link rel="preconnect" href="https://fonts.googleapis.com" />
                <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
                <link
                  href="https://fonts.googleapis.com/css2?family=Libre+Caslon+Text:ital,wght@0,400;0,700;1,400&family=Source+Sans+Pro:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap"
                  rel="stylesheet"
                />
              </Helmet>
            )}
            <div className="App">
              <Navigation history={history} />
              <div className="center">
                <div className="container">
                  <Inform lang={getLocale()} />

                  <ScrollToTop />

                  {/* Display content when data is loaded */}
                  {servicesInitialLoadComplete &&
                    subServicesInitialLoadComplete &&
                    thankYouPageInitialLoadComplete &&
                    appointmentTypesInitialLoadComplete &&
                    landingPageInitialLoadComplete &&
                    redirectionInitialLoadComplete && (
                      <Switch>
                        <Route
                          exact
                          path={`/${getLocale()}`}
                          render={props => <Landing {...props} lang={getLocale()} />}
                        />
                        <Route
                          exact
                          path={`/${getLocale()}/details`}
                          render={props =>
                            allowConfirmReservation() ? (
                              <ConfirmReservation {...props} lang={getLocale()} />
                            ) : (
                              <Redirect to={`/${getLocale()}`}></Redirect>
                            )
                          }
                        />
                        <Route
                          exact
                          path={`/${getLocale()}/confirmed`}
                          render={props =>
                            allowThankYou() ? (
                              <ThankYou {...props} lang={getLocale()} />
                            ) : (
                              <Redirect to={`/${getLocale()}`}></Redirect>
                            )
                          }
                        />
                        <Route
                          exact
                          path={`/${getLocale()}/login/:bookingref?`}
                          render={props => <AppointmentLogin {...props} lang={getLocale()} />}
                        />
                        <Route
                          exact
                          path={`/${getLocale()}/appointment/my-appointments`}
                          render={props =>
                            allowAppointments() ? (
                              <Appointments {...props} lang={getLocale()} />
                            ) : (
                              <Redirect to={`/${getLocale()}`}></Redirect>
                            )
                          }
                        />
                        <Route
                          exact
                          path={`/${getLocale()}/appointment/cancelled`}
                          render={props =>
                            allowCancelled() ? (
                              <AppointmentLogin cancelled={true} {...props} lang={getLocale()} />
                            ) : (
                              <Redirect to={`/${getLocale()}`}></Redirect>
                            )
                          }
                        />
                        <Route
                          exact
                          path={`/${getLocale()}/appointment/logout`}
                          render={props => <AppointmentLogin logout={true} {...props} lang={getLocale()} />}
                        />
                        <Route
                          path={`/${getLocale()}/:slug/:city?/:store?`}
                          render={props =>
                            redirectionCheck(props) ? (
                              <Redirect to={formRedirectionUrl(props, this.props)}></Redirect>
                            ) : allowBook(props, this.props) ? (
                              <SelectService {...props} lang={getLocale()} />
                            ) : (
                              <Redirect to={`/${getLocale()}`}></Redirect>
                            )
                          }
                        />
                        <Route
                          exact
                          path="*"
                          render={props => {
                            const lang = location.pathname.split('/')[1];
                            let redirectTo = <Redirect to={`/${getLocale()}`}></Redirect>;

                            // Language change in url -> redirect accordingly
                            if (lang !== getLocale()) {
                              // Language misspelled -> redirect to current lang baseurl
                              if (!R.includes(lang, ['fi', 'en', 'sv'])) {
                                return redirectTo;
                              }

                              changeLanguage(lang, i18n, saveLocale);
                              redirectTo = <Redirect to={`/${lang}`}></Redirect>;
                            }

                            return redirectTo;
                          }}
                        />
                      </Switch>
                    )}

                  {/* Display error if no API connections available */}
                  {errorConnectingContentful &&
                    !servicesInitialLoadComplete &&
                    !subServicesInitialLoadComplete &&
                    !thankYouPageInitialLoadComplete &&
                    !appointmentTypesInitialLoadComplete && <Error t={t} />}

                  {/* Display loading symbol while waiting APIs to answer */}
                  {!errorConnectingContentful &&
                    !servicesInitialLoadComplete &&
                    !subServicesInitialLoadComplete &&
                    !thankYouPageInitialLoadComplete &&
                    !appointmentTypesInitialLoadComplete && <CircularProgress style={{ marginTop: 100 }} />}
                </div>
              </div>
              <Footer />
              <InfoBanner />
            </div>
          </>
        )}
      </StylesProvider>
    );
  }
}

const mapStateToProps = state => {
  return {
    serviceMounted: R.path(['availability', 'serviceMounted'], state),
    appointmentTypesInitialLoadComplete: R.path(['availability', 'appointmentTypesInitialLoadComplete'], state),
    servicesInitialLoadComplete: R.path(['contentful', 'servicesInitialLoadComplete'], state),
    subServicesInitialLoadComplete: R.path(['contentful', 'subServicesInitialLoadComplete'], state),
    thankYouPageInitialLoadComplete: R.path(['contentful', 'thankYouPageInitialLoadComplete'], state),
    redirectionInitialLoadComplete: R.path(['contentful', 'redirectionInitialLoadComplete'], state),
    layoutInitialLoadComplete: R.path(['contentful', 'layoutInitialLoadComplete'], state),
    landingPageInitialLoadComplete: R.path(['contentful', 'landingPageInitialLoadComplete'], state),
    errorConnectingContentful: R.path(['contentful', 'errorConnectingContentful'], state),
    stores: R.path(['availability', 'stores'], state),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  getDateTime: () => dispatch(getDateTime()),
  getServices: () => dispatch(getServices()),
  getSubServices: () => dispatch(getSubServices()),
  getThankYouPage: () => dispatch(getThankYouPage()),
  getLandingPage: () => dispatch(getLandingPage()),
  getRedirections: () => dispatch(getRedirections()),
  getLayout: () => dispatch(getLayout()),
  getClosestShopToCustomer: () => dispatch(getClosestShopToCustomer()),
  getAppointmentTypes: () => dispatch(getAppointmentTypes()),
  saveLocale: locale => dispatch(saveLocale(locale)),
  selectService: values => dispatch(selectService(values, R.path(['history'], ownProps), R.path(['lang'], ownProps))),
  saveUsedRedirection: redirect => dispatch(saveUsedRedirection(redirect)),
  trackSaveUrlFragments: values => dispatch(trackSaveUrlFragments(values)),
  trackUrlChange: () => dispatch(trackUrlChange()),
  setServiceMounted: () => dispatch(setServiceMounted()),
});

App = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation('translations')(App)));

export default App;
