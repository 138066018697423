import * as grandVisionApi from '../../api/grandVisionApi';
import * as R from 'ramda';
import sphereKnn from 'sphere-knn';
import { trackConfirmReservation } from './trackingActions';
import moment from 'moment';

export const GET_DATETIME = 'GET_DATETIME';
export const GET_DATETIME_FAILURE = 'GET_DATETIME_FAILURE';
export const GET_APPOINTMENTTYPES = 'GET_APPOINTMENTTYPES';
export const GET_APPOINTMENTTYPES_FAILURE = 'GET_APPOINTMENTTYPES_FAILURE';
export const GET_AVAILABILITY = 'GET_AVAILABILITY';
export const GET_AVAILABILITY_FAILURE = 'GET_AVAILABILITY_FAILURE';
export const GET_AVAILABILITY_MULTIPLE_STORES = 'GET_AVAILABILITY_MULTIPLE_STORES';
export const GET_AVAILABILITY_MULTIPLE_STORES_FAILURE = 'GET_AVAILABILITY_MULTIPLE_STORES_FAILURE';
export const GET_SPECIALISTS = 'GET_SPECIALISTS';
export const GET_SPECIALISTS_IN_PROGRESS = 'GET_SPECIALISTS_IN_PROGRESS';
export const GET_SPECIALISTS_FAILURE = 'GET_SPECIALISTS_FAILURE';
export const GET_AVAILABILITY_IN_PROGRESS = 'GET_AVAILABILITY_IN_PROGRESS';
export const GET_ALL_STORES = 'GET_ALL_STORES';
export const GET_ALL_STORES_FAILURE = 'GET_ALL_STORES_FAILURE';
export const MAKE_RESERVATION = 'MAKE_RESERVATION';
export const MAKE_RESERVATION_FAILURE = 'MAKE_RESERVATION_FAILURE';
export const SELECT_SERVICE = 'SELECT_SERVICE';
export const SELECT_TIMESLOT = 'SELECT_TIMESLOT';
export const CLEAR_DATA = 'CLEAR_DATA';
export const CLEAR_AVAILABILITY = 'CLEAR_AVAILABILITY';
export const CLEAR_FETCH_TIMESTAMP = 'CLEAR_FETCH_TIMESTAMP';
export const CLEAR_DATA_AVAILABILITY = 'CLEAR_DATA_AVAILABILITY';
export const CLEAR_MONTH_AVAILABILITY = 'CLEAR_MONTH_AVAILABILITY';
export const CLEAR_AVAILABLE_OPTICIANS = 'CLEAR_AVAILABLE_OPTICIANS';
export const GET_AVAILABILITY_BASED_ON_A_SPECIALIST = 'GET_AVAILABILITY_BASED_ON_A_SPECIALIST';
export const GET_AVAILABILITY_BASED_ON_A_SPECIALIST_FAILURE = 'GET_AVAILABILITY_BASED_ON_A_SPECIALIST_FAILURE';
export const SET_SERVICE_MOUNTED = 'SET_SERVICE_MOUNTED';
export const SET_AVAILABILITY_START_DATE = 'SET_AVAILABILITY_START_DATE';

/**
 * Get date
 */
export const getDateTime = () => dispatch => {
  grandVisionApi
    .getDateTime()
    .then(results => {
      Promise.all([
        dispatch({
          type: GET_DATETIME,
          results,
        }),
      ]);
    })
    .catch(error =>
      Promise.all([
        dispatch({
          type: `${GET_DATETIME}_FAILURE`,
          error,
        }),
      ]),
    );
};
/**
 * Get appointment types from GrandVision API
 */
export const getAppointmentTypes = () => dispatch => {
  grandVisionApi
    .getAppointmentTypes()
    .then(results => {
      Promise.all([
        dispatch({
          type: GET_APPOINTMENTTYPES,
          results,
        }),
      ]);
    })
    .catch(error =>
      Promise.all([
        dispatch({
          type: `${GET_APPOINTMENTTYPES}_FAILURE`,
          error,
        }),
      ]),
    );
};

/**
 * Clear availability on city change
 */
export const clearAvailability = () => dispatch => {
  dispatch({
    type: CLEAR_AVAILABILITY,
  });
};

/**
 * Clear availability fetch time stamp
 */
export const clearFetchTimeStamp = () => dispatch => {
  dispatch({
    type: CLEAR_FETCH_TIMESTAMP,
  });
};

/**
 * Get time slot availability from GrandVision API
 */
export const getSpecialists = values => (dispatch, getState) => {
  // Get selected service ID
  const selectedServiceID = R.path(['availability', 'grandVisionApiId'], getState());

  // Get all stores of the user selected city
  const selectedCity = R.path(['city'], values);

  if (!selectedCity) {
    return;
  }

  dispatch({
    type: GET_SPECIALISTS_IN_PROGRESS,
  });

  const storesGroupedByCities = R.path(['availability', 'storesGroupedByCities'], getState());
  const selectedCityStores = R.path([selectedCity], storesGroupedByCities);

  // If selected city has a lot of stores -> we will get availability for them
  // Otherwise we have to calculate a few more stores per city coordinates

  let nearbyStores = [];
  let selectedStore = R.path(['store'], values);

  if (selectedStore) {
    let selectedStoreJson = JSON.parse(selectedStore);
    nearbyStores = [{ storeId: R.path(['key'], selectedStoreJson), city: R.path(['value'], selectedStoreJson) }];
  } else if (selectedCityStores && selectedCityStores.length < 2) {
    const selectedCityCoordinates = R.path(['attributes', 'location'], R.head(selectedCityStores));
    const latCity = R.path(['latitude'], selectedCityCoordinates);
    const lonCity = R.path(['longitude'], selectedCityCoordinates);

    const storesLocations = R.path(['availability', 'storesLocations'], getState());

    // Determine closest store coordinates to selected city coordinates
    const lookup = sphereKnn(storesLocations);
    nearbyStores = lookup(latCity, lonCity, 3);
  } else {
    nearbyStores = selectedCityStores.map(item => ({
      latitude: item.attributes.location.latitude,
      longitude: item.attributes.location.longitude,
      storeId: item.id,
      city: item.attributes.address.city,
    }));
  }

  grandVisionApi
    .getSpecialists(nearbyStores, selectedServiceID)
    .then(results => {
      Promise.all([
        dispatch({
          type: GET_SPECIALISTS,
          results,
        }),
      ]);
    })
    .catch(error =>
      Promise.all([
        dispatch({
          type: `${GET_SPECIALISTS}_FAILURE`,
          error,
        }),
      ]),
    );
};

/**
 * Get time slot availability from GrandVision API
 */
export const getAvailability = values => (dispatch, getState) => {
  const fetchedMonths = R.path(['availability', 'fetchedMonthAvailabilities'], getState());

  if (fetchedMonths && fetchedMonths.includes(moment(R.path(['availabilityStartDate'], values)).format('MM-YYYY'))) {
    // The availability for this month was fetched already
    return;
  }

  // Get selected service ID
  const selectedServiceID = R.path(['availability', 'grandVisionApiId'], getState());

  // Get all stores of the user selected city
  const selectedCity = R.path(['city'], values);

  if (!selectedCity) {
    return;
  }

  dispatch({
    type: GET_AVAILABILITY_IN_PROGRESS,
  });

  let availabilityStartDate = R.path(['availabilityStartDate'], values);

  if (!availabilityStartDate) {
    availabilityStartDate = R.path(['availability', 'availabilityStartDate'], getState());
  }

  const storesGroupedByCities = R.path(['availability', 'storesGroupedByCities'], getState());
  const selectedCityStores = R.path([selectedCity], storesGroupedByCities);

  // If selected city has a lot of stores -> we will get availability for them
  // Otherwise we have to calculate a few more stores per city coordinates

  let nearbyStores = [];
  let selectedStore = R.path(['store'], values);

  if (selectedStore) {
    let selectedStoreJson = JSON.parse(selectedStore);
    nearbyStores = [{ storeId: R.path(['key'], selectedStoreJson), city: R.path(['value'], selectedStoreJson) }];
  } else if (selectedCityStores && selectedCityStores.length < 2) {
    const selectedCityCoordinates = R.path(['attributes', 'location'], R.head(selectedCityStores));
    const latCity = R.path(['latitude'], selectedCityCoordinates);
    const lonCity = R.path(['longitude'], selectedCityCoordinates);

    const storesLocations = R.path(['availability', 'storesLocations'], getState());

    // Determine closest store coordinates to selected city coordinates
    const lookup = sphereKnn(storesLocations);
    nearbyStores = lookup(latCity, lonCity, 3);
  } else {
    nearbyStores = selectedCityStores.map(item => ({
      latitude: item.attributes.location.latitude,
      longitude: item.attributes.location.longitude,
      storeId: item.id,
      city: item.attributes.address.city,
    }));
  }

  const selectedOptician = R.path(['person'], values);
  const selectedOpticianParsed = selectedOptician ? JSON.parse(selectedOptician).key : '';

  grandVisionApi
    .getAvailabilityForMultipleStores(
      values,
      nearbyStores,
      selectedServiceID,
      selectedOpticianParsed,
      availabilityStartDate,
    )
    .then(results => {
      Promise.all([
        dispatch({
          type: GET_AVAILABILITY_MULTIPLE_STORES,
          results,
          nearbyStores,
          availabilityStartDate,
        }),
      ]);
    })
    .catch(error =>
      Promise.all([
        dispatch({
          type: `${GET_AVAILABILITY_MULTIPLE_STORES}_FAILURE`,
          error,
        }),
      ]),
    );
};

/**
 * Get all stores from GrandVision API
 */
export const getAllStores = () => dispatch =>
  grandVisionApi
    .getAllStores()
    .then(results => {
      return Promise.all([
        dispatch({
          type: GET_ALL_STORES,
          results,
        }),
      ]);
    })
    .catch(error =>
      Promise.all([
        dispatch({
          type: `${GET_ALL_STORES}_FAILURE`,
          error,
        }),
      ]),
    );

/**
 * Save user filled in values to Store
 * @param {object} values         User filled in values
 * @param {object} history        History object for redirecting
 */
export const selectService = (values, history, lang) => dispatch => {
  dispatch({
    type: SELECT_SERVICE,
    values,
  });
};

/**
 * Save user filled in values to Store and redirect user to next step
 * @param {object} values         User filled in values
 * @param {object} history        History object for redirecting
 */
export const selectTimeSlot = (values, history, lang) => dispatch => {
  const opticianId = R.path(['selectedOptician'], values);
  const opticianName = R.path(['selectedOpticianName'], values);

  if (!opticianName && opticianId) {
    grandVisionApi.getSpecialistById(opticianId).then(results => {
      const optician = R.path(['data', 0, 'attributes'], results);

      values['selectedOpticianName'] = `${optician.firstName} ${optician.lastName}, ${optician.title}`;

      Promise.all(
        [
          dispatch({
            type: SELECT_TIMESLOT,
            values,
          }),
        ],
        history && history.push(`/${lang}/details`),
        dispatch(trackConfirmReservation()),
      );
    });
  }

  dispatch({
    type: SELECT_TIMESLOT,
    values,
  });

  history && history.push(`/${lang}/details`);

  dispatch(trackConfirmReservation());
};

/**
 * Clear user filled in data and selections from store to allow user to start a new reservation
 */
export const clearData = () => dispatch => {
  dispatch({
    type: CLEAR_DATA,
  });
};

/**
 * Clear all data
 */
export const clearDataAvailability = () => dispatch => {
  dispatch({
    type: CLEAR_DATA_AVAILABILITY,
  });
};

/**
 * Clear month availability data
 */
export const clearMonthAvailability = () => dispatch => {
  dispatch({
    type: CLEAR_MONTH_AVAILABILITY,
  });
};

/**
 * Clear selected opticians
 */
export const clearAvailableOpticians = () => dispatch => {
  dispatch({
    type: CLEAR_AVAILABLE_OPTICIANS,
  });
};

/**
 * Clear all data
 */
export const setServiceMounted = () => dispatch => {
  dispatch({
    type: SET_SERVICE_MOUNTED,
  });
};

/**
 * Set availability check start date
 */
export const setAvailabilityStartDate = values => dispatch => {
  dispatch({
    type: SET_AVAILABILITY_START_DATE,
    values,
  });
};
