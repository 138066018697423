import { applyMiddleware, compose, createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';

import rootReducer from './reducers/rootReducer';

/**
 * Load Dev tools to be able to user Redux Store data in localhost development
 */
function loadDevTools() {
  /* eslint-disable */
  return process.env.NODE_ENV === 'development' && typeof window !== 'undefined' && window.devToolsExtension
    ? window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    : f => f;
  /* eslint-enable */
}

/**
 * Redux store data is persisted
 * If any data should NOT be peristed list the name of the reducer in blacklist array
 */
const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: autoMergeLevel2,
  blacklist: [
    'pagination',
    'errors',
    'contentful',
    'availability.serviceMounted',
    'availability.appointment_types',
    'availability.available_time_slots',
    'availability.availabilityCallInProgress',
    'availability.fetchMonthAvailabilities',
    'appointment',
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(persistedReducer, compose(applyMiddleware(thunk), loadDevTools()));

export const persistor = persistStore(store);
